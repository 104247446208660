<template>
  <main class="PackagesExport">
    <TheSubHeader 
      icon="export-alt" 
      title="Exportar Pacotes"
      page-title-tag="Packages Export"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="240px repeat(5, 1fr) 135px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas (max. 15 dias)"
          :max-range="15"
          :not-after="todayParsed"
          @input="resetSearch()"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.stores"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.drivers"
          name="Motorista"
          label="Motorista"
          placeholder="Selecione"
          multiple
          suggestions="searchDrivers"
          @input="resetSearch()"
        />
        <!-- Packages -->
        <BaseTreeSelect
          v-model="filters.order_product_codes"
          name="Pacote"
          label="Pacote"
          placeholder="Código do pacote"
          multiple
          enable-paste
          :select-all="false"
          :allow-create="true"
          :disable-branch-nodes="true"
          @input="resetSearch()"
        />
        <!-- Orders -->
        <BaseTreeSelect
          v-model="filters.order_numbers"
          name="Pedido"
          label="Pedido"
          placeholder="Nº do pedido"
          multiple
          enable-paste
          :select-all="false"
          :allow-create="true"
          :disable-branch-nodes="true"
          @input="resetSearch()"
        />
        <!-- Sellers -->
        <BaseTreeSelect
          v-model="filters.seller_ids"
          name="Sellers"
          label="Sellers"
          placeholder="Pesquisar"
          multiple
          :enable-paste="false"
          :min-search-length="2"
          suggestions="searchSellers"
          @input="resetSearch()"
        />

        <!-- Export -->
        <BaseButton 
          :disabled="exportDisabled"
          label="Exportar" :class="internStatus" theme="simple" filled color="primary-base" text-color="white" @click="exportReport"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="PackagesExport__content">
      <h1 class="PackagesExport__Title">
        HISTÓRICO DE EXPORTAÇÃO
      </h1>
      <div class="PackagesExport__table">
        <DataTable
          is-sticky
          :header="packagesReportsRequestedWithLoading.data.headers"
          :table="packagesReportsRequestedWithLoading.data.content"
          @download="download"
          @seeErrors="showDefaultErrorMessage"
        />
        <Pagination @page="fetchPackagesReports()" @paginate="fetchPackagesReports()" />
      </div>
    </div>
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag } from '@/components/molecules'
import { packages } from '@/api'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'PackagesExport',
  components: {
    TheSubHeader,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    Pagination,
    BaseButton,
    FiltersTag
  },
  
  data(){
    return {
      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        stores: [],
        drivers: [],
        order_product_codes: [],
        order_numbers: [],
        seller_ids: []
      },


      intern: true,
      // store: false,
      date_start: null,
      date_end: null,
      allTags: [],
      timeOutUpdate: 10 * 1000,
      time: null,
      updating: false
    }
  },
  computed:{
    ...mapState({
      packagesReportsRequested: state => state.packages.packagesReportsRequested,
      permissions: state => state.auth.permissions,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    ...mapGetters({
      packagesReportsRequestedWithLoading: 'packages/packagesReportsRequestedWithLoading'
    }),

    //

    internStatus() {
      return this.filters.dateRange.init && this.filters.dateRange.end ? 'PackagesExport__able' : 'PackagesExport__disable'
    },

    exportDisabled() {
      return !((this.filters.stores.length > 0 && this.filters.drivers.length > 0) || this.filters.stores.length > 0)
    },

    //Helpers
    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    dateRangeInit() {
      const days = 0
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    }, 
    
  },

  async mounted(){
    this.clearPagination()
    this.setInitialDate()
    this.fetchStores()
    this.setLoading(true)
    await this.fetchPackagesReports()
    this.setLoading(false)
  },

  methods:{
    ...mapActions({
      fetchPackagesReportsRequested: 'packages/fetchPackagesReportsRequested',
      fetchStores: 'stores/fetchStores',
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setError: 'error/setError'
    }),

    showDefaultErrorMessage() {
      this.setError('Ocorreu um erro ao exportar esse relatório, tente novamente ou entre em contato com o suporte.')
    },

    updateTags(){
      this.allTags = [
        ...this.filters.stores, 
        ...this.filters.drivers, 
        ...this.filters.order_product_codes, 
        ...this.filters.order_numbers,
        ...this.filters.seller_ids
      ]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.stores = this.filters.stores.filter((tag) => tag.name !== name)
      this.filters.drivers = this.filters.drivers.filter((tag) => tag.name !== name)
      this.filters.order_product_codes = this.filters.order_product_codes.filter((tag) => tag.name !== name)
      this.filters.order_numbers = this.filters.order_numbers.filter((tag) => tag.name !== name)
      this.filters.seller_ids = this.filters.seller_ids.filter((tag) => tag.name !== name)
      
      this.resetSearch()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.stores = []
      this.filters.drivers = []
      this.filters.order_product_codes = []
      this.filters.order_numbers = []
      this.filters.seller_ids = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search() {
      this.updateTags()
    },

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    fetchPackagesReports(loading = true) {
      if (loading) {
        this.setLoading(true)
      }
      this.fetchPackagesReportsRequested({page: this.page, paginate: this.paginate}).then(() => {
        this.setPagination(this.packagesReportsRequested)
        if (
          JSON.stringify(this.packagesReportsRequested.data.content).indexOf('Andamento') > 0 && 
          this.$route.name === 'packagesExport'
        ) {
          if (!this.updating) {
            this.updating = true
            this.time = setTimeout(() => {
              this.fetchPackagesReports(false)
              this.updating = false
            }, this.timeOutUpdate + this.time)
          }
        } else {
          clearTimeout(this.time)
        }
      })
    },

    async exportReport(){
      this.setLoading(true)

      const obj = {
        date_start: String(this.filters.dateRange.init),
        date_end: String(this.filters.dateRange.end),
        'store_id': this.idsFiltereds('stores'),
        'driver_id': this.idsFiltereds('drivers'),
        'order_product_codes': this.idsFiltereds('order_product_codes'),
        'order_numbers': this.idsFiltereds('order_numbers'),
        'seller_ids': this.idsFiltereds('seller_ids'),
      }

      await packages.exportPackagesReport(obj, (r) => r, (e) => e, () => {
        this.fetchPackagesReports()
      })
    },

    // Search
    idsFiltereds(name){
      if (!name) {
        return []
      }
      return this.filters[name].map(el => {
        return el?.id
      })
    },

    async download(data) {
      if (data.fileName != null) {
        this.setLoading(true)
        await packages.downloadPackagesReport(data.id, (r) => r, (e) => e, () => this.setLoading(false))
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.PackagesExport {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". title ."
                         ". main .";
    grid-column-gap: 1rem;
  }
  &__Title {
    grid-area: title;
    font-weight: $font-weight-bold;
    font-style: normal;
    font-size: $font-size-mini;
    color: $color-neutral-stronger;
    margin-bottom: 20px;
  }
  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }
  &__disable{
    pointer-events: none;
    opacity: .3;
  }
}
</style>
<style lang="scss">
.PackagesExport {
  .Organizer__filter {
    align-items: flex-end;
  }
}
</style>